import React, { Component } from "react";
import "../../css/pages/homepage/rails.scss";

class Rails extends Component {
  render() {
    return (
      <>
      <div className="rail">
        <div className="rail__container">
            <div className="rail__track first">
                <span className="rail__item">Front End Developer.</span>
                <span className="rail__item">HackHarvard 2019.</span>
                <span className="rail__item">UI Developer.</span>
                <span className="rail__item">Visionary.</span>
                <span className="rail__item">Podcaster.</span>
            </div>
        </div>

        <hr className="rail__divider"/>

        <div className="rail__container theme">
          <div className="rail__track second">
              <span className="rail__item">CSS Enthusiast.</span>
              <span className="rail__item">Awwwards.</span>
              <span className="rail__item">UI/UX Designer.</span>
              <span className="rail__item">Tech Speaker.</span>
              <span className="rail__item">Hustler.</span>
          </div>
        </div>

        <hr className="rail__divider"/>

        <div className="rail__container">
          <div className="rail__track third">
              <span className="rail__item">Designer.</span>
              <span className="rail__item">The Hustle Radio.</span>
              <span className="rail__item">Letzcode.</span>
              <span className="rail__item">WebDevGoa.</span>
              <span className="rail__item">Hustle.</span>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Rails;
