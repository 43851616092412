import React, { Component } from "react";
import { Parallax } from "react-scroll-parallax";

// css
import "../../css/components/content-card.scss";

// images
import podcastImage from "./images/podcast.jpg";
import hackHarvardImage from "./images/hackHarvard.jpg";
import a11yImage from "./images/a11y1.png";

class ContentCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      on_link: false,
      mobile_view: true,
    };
  }

  overLink = (type) => {
    this.setState({
      on_link: true,
    });
    document.getElementById("cursor").classList.add("play");

    let innerCircle = document.getElementById("small-cursor");

    if (type === "play") {
      innerCircle.classList.add("play-icon");
    } else if (type === "chevron") {
      innerCircle.classList.add("chevron-icon");
    }
  };

  notOverLink = (type) => {
    this.setState({
      on_link: false,
    });
    document.getElementById("cursor").classList.remove("play");

    let innerCircle = document.getElementById("small-cursor");

    if (type === "play") {
      innerCircle.classList.remove("play-icon");
    } else if (type === "chevron") {
      innerCircle.classList.remove("chevron-icon");
    }
  };

  componentDidMount = () => {
    if (window.innerWidth < 641) {
      this.setState({
        mobile_view: true,
      });
    } else {
      this.setState({
        mobile_view: false,
      });
    }
  };

  render() {
    return (
      <>
        <div className="cell large-4 medium-6">
          <Parallax
            y={[0, -30]}
            disabled={this.state.mobile_view ? true : false}
          >
            <a
              href="https://open.spotify.com/episode/2k2dqNncKN5cOslhu7iFUU"
              target="blank"
            >
              <div className="content-card">
                <img
                  src={podcastImage}
                  alt="post accompanying content"
                  className="content-card__image"
                />
                <div className="content-card__text-container">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="content-card__tag">Podcast</p>
                    <p className="content-card__date">
                      <time dateTime="2020-08-21 20:00">Aug 21, 2020</time>
                    </p>
                  </div>
                  <p className="content-card__title">
                    Ep. 1 - The 10 minute rule.
                  </p>
                </div>
                <a
                  href="https://open.spotify.com/episode/2k2dqNncKN5cOslhu7iFUU"
                  target="blank"
                  onMouseEnter={() => this.overLink("play")}
                  onMouseLeave={() => this.notOverLink("play")}
                  className="button"
                >
                  <div className="content-card__button">Play on Spotify</div>
                </a>
              </div>
            </a>
          </Parallax>
        </div>

        <div className="cell large-4 medium-6">
          <Parallax disabled={this.state.mobile_view ? true : false}>
            <a
              href="https://medium.com/@salil.naik27/my-hackharvard-2019-experience-da1b64f4e5c5"
              target="blank"
            >
              <div className="content-card">
                <img
                  src={hackHarvardImage}
                  alt="post accompanying content"
                  className="content-card__image"
                />
                <div className="content-card__text-container">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="content-card__tag">Blog</p>
                    <p className="content-card__date">
                      <time dateTime="2020-09-10 8:30">Sept 10, 2020</time>
                    </p>
                  </div>
                  <p className="content-card__title">
                    My HackHarvard 2019 Experience
                  </p>
                </div>
                <a
                  href="https://medium.com/@salil.naik27/my-hackharvard-2019-experience-da1b64f4e5c5"
                  target="blank"
                  onMouseEnter={() => this.overLink("chevron")}
                  onMouseLeave={() => this.notOverLink("chevron")}
                  className="button"
                >
                  <div className="content-card__button">Read the article</div>
                </a>
              </div>
            </a>
          </Parallax>
        </div>

        <div className="cell large-4 medium-6">
          <Parallax
            y={[0, 30]}
            disabled={this.state.mobile_view ? true : false}
          >
             <a
              href="https://dev.to/salilnaik/what-is-web-a11y-and-why-is-it-important-4fic"
              target="blank"
            >
            <div className="content-card">
              <img
                src={a11yImage}
                alt="post accompanying content"
                className="content-card__image"
              />
              <div className="content-card__text-container">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p className="content-card__tag">Blog</p>
                  <p className="content-card__date">
                    <time dateTime="2020-10-1 19:00">Oct 1, 2020</time>
                  </p>
                </div>
                <p className="content-card__title">
                  What is A11y and why is it important?
                </p>
              </div>
              <a
                href="https://dev.to/salilnaik/what-is-web-a11y-and-why-is-it-important-4fic"
                target="blank"
                onMouseEnter={() => this.overLink("chevron")}
                onMouseLeave={() => this.notOverLink("chevron")}
                className="button"
              >
              <div className="content-card__button">Read the article</div>
              </a>
            </div>
            </a>
          </Parallax>
        </div>
      </>
    );
  }
}

export default ContentCard;
