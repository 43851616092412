// packages
import React, { Component } from "react";
import { Parallax } from "react-scroll-parallax";

// components
import Header from "../../../components/Header.jsx";
import Iphone from "./iphone.jsx";

// css
import "../../../css/pages/dte.scss";

// images
import screenshot_1_webp from "./images/ss1-webp-1500.webp";
import screenshot_1 from "./images/ss1-jpg-800.jpg";
import iphone_1 from "./images/iphone-1.png";
import screenshot_2 from "./images/screenshot-2.png";
import screenshot_3 from "./images/screenshot-3.png";
import ss4 from "./images/ss4-png-1000.png";
import ss4_webp from "./images/ss4-webp-1200.webp";
import ss4_mobile from "./images/ss4-mobile-700.png";
import ss4_mobile_webp from "./images/ss4-mobile-700.webp";
import ScrollImg from "../../../components/images/scroll.png";

class Dte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile_view: true,
    };
  }
  componentDidMount = () => {
    if (window.innerWidth < 641) {
      this.setState({
        mobile_view: true,
      });
    } else {
      this.setState({
        mobile_view: false,
      });
    }
  };
  render() {
    return (
      <div style={{ overflowX: "hidden" }}>
        <Header homepage={false} />
        <div className="dte">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell large-12">
                <p className="dte__deliverables">
                  Design and Front End Development
                </p>
                <h1 className="dte__title">
                  Directorate of Technical Education, Government of Goa
                </h1>
                <img
                  src={ScrollImg}
                  alt="scroll rounded"
                  className="scroll-image"
                  loading="lazy"
                />
                <div className="dte__image-container">
                  <div className="grid-x grid-margin-x">
                    <div className="cell medium-8">
                      <picture>
                        <source
                          media="(min-width:641px)"
                          srcset={screenshot_1_webp}
                        />
                        <img
                          src={screenshot_1}
                          alt="screenshot of the academic details page"
                          loading="lazy"
                          className="screenshot"
                        />
                      </picture>
                    </div>

                    <div className="cell medium-4">
                      <Parallax
                        y={[
                          this.state.mobile_view ? "300px" : "400px",
                          this.state.mobile_view ? "100px" : "80px",
                        ]}
                      >
                        <img
                          src={iphone_1}
                          alt="iphone with website screenshot"
                          className="iphone"
                          loading="lazy"
                        />
                      </Parallax>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cell medium-6">
                <div className="dte-content">
                  <p>
                    The Directorate of Technical Education (DTE) took the
                    initiative to receive applications for admissions to
                    professional degree and diploma courses online in the wake
                    of COVID 19.
                    <br />
                    <br />I was responsible for building 4 website for them in
                    order to facilitate online submission of application forms.
                  </p>
                </div>
              </div>

              <div className="cell medium-6">
                <div className="dte-content">
                  <section style={{ marginBottom: "30px" }}>
                    <h4 className="dte-content__heading">Achievements</h4>
                    <p>
                      This automated an antiquated admission system followed by
                      the Government for over 20 years and eliminated the need
                      for over 20000 students and their parents to travel during
                      this pandemic.
                    </p>
                  </section>

                  <section>
                    <h4 className="dte-content__heading">Honors</h4>
                    <p>
                      Opportunity to work with the State Government and help
                      them in the times of global crises.
                    </p>
                  </section>
                </div>
              </div>

              {/* 2 images */}
              <div
                className="cell medium-6"
                style={{ display: this.state.mobile_view ? "none" : "block" }}
              >
                <div className="dte__two-images">
                  <Parallax y={["0px", "-300px"]}>
                    <img
                      src={screenshot_3}
                      alt="screenshot of the instructions page"
                      loading="lazy"
                    />
                  </Parallax>
                </div>
              </div>

              <div className="cell medium-6">
                <div className="dte__two-images">
                  <img
                    src={screenshot_2}
                    alt="screenshot of diploma website profile"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Iphone />
        <div className="grid-container" style={{ marginBottom: "70px" }}>
          <div className="grid-x grid-margin-x">
            <div className="cell large-12">
              <div className="dte__image-container equal-padding">
                <picture>
                  <source  media="(max-width:640px)" srcset={ss4_mobile_webp} />
                  <source  media="(max-width:640px)" srcset={ss4_mobile} />
                  <source srcset={ss4_webp} />
                  <img
                    src={ss4}
                    alt="screenshot of the academic details page"
                    loading="lazy"
                    className="screenshot"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dte;
