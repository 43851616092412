import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

// css grid
import "./product.css";

// componenets
import Footer from "./components/Footer.jsx";
import Cursor from "./components/Cursor.jsx";

// pages
import Home from "./pages/homepage/Home.jsx";
import Dte from  "./pages/work/dte-goa/Dte.jsx";

class App extends Component {
  handleMouseMove = (e) => {
    const cursor = document.getElementById("cursor");
    cursor.classList.remove("hide");
    cursor.style.left = `${e.pageX}px`;
    cursor.style.top = `${e.pageY}px`;
    console.log(window.scrollY);
  };

  hideMouse = (e) => {
    document.getElementById("cursor").classList.add("hide");
  };

  render() {
    return (
      <div onMouseMove={this.handleMouseMove} onMouseOut={this.hideMouse}>
        <Cursor />
        <Router>
          <ParallaxProvider>
            <Switch>
              <Route exact path="/" component={Home} />
              {/* <Route exact path="/dte" component={Dte} /> */}
              <Dte 
                exact 
                path="/dte"
              />
            </Switch>
          </ParallaxProvider>

          <Footer />
        </Router>
      </div>
    );
  }
}

export default App;
