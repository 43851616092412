import React, { Component } from "react";
import "../css/components/footer.scss";

class Footer extends Component {
  overLink = () => {
    document.getElementById("cursor").classList.add("play", "bg-transparency");
  };

  notOverLink = () => {
    document
      .getElementById("cursor")
      .classList.remove("play", "bg-transparency");
  };

  render() {
    return (
      <>
        <footer className="footer" id="footer">
          <hr className="rail__divider" />
          <div className="section">
            <div className="grid-container">
              <div className="grid-x grid-margin-x">
                <div className="cell large-12">
                  <p className="footer__text">Say hello to me</p>
                  <a
                    href="mailto:salil.naik27@gmail.com"
                    className="footer__email"
                    onMouseEnter={this.overLink}
                    onMouseLeave={this.notOverLink}
                  >
                    <span>salil.naik27</span>@gmail.com
                  </a>
                  <div className="social_links">
                    <a
                      className="social_links__item"
                      href="https://www.awwwards.com/SalilNaik/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={this.overLink}
                      onMouseLeave={this.notOverLink}
                    >
                      Awwwards
                    </a>
                    <a
                      className="social_links__item"
                      href="https://github.com/salil-naik"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={this.overLink}
                      onMouseLeave={this.notOverLink}
                    >
                      GitHub
                    </a>
                    <a
                      className="social_links__item"
                      href="https://twitter.com/0xSalilNaik"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={this.overLink}
                      onMouseLeave={this.notOverLink}
                    >
                      Twitter
                    </a>
                    <a
                      className="social_links__item"
                      href="https://www.linkedin.com/in/salilnaik/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={this.overLink}
                      onMouseLeave={this.notOverLink}
                    >
                      LinkedIn
                    </a>
                    <a
                      className="social_links__item"
                      href="https://www.instagram.com/0xsalilnaik"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={this.overLink}
                      onMouseLeave={this.notOverLink}
                    >
                      Instagram
                    </a>
                    {/* <a
                      className="social_links__item"
                      href="https://dribbble.com/salilnaik"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={this.overLink}
                      onMouseLeave={this.notOverLink}
                    >
                      Dribbble
                    </a> */}
                    <a
                      className="social_links__item"
                      href="https://codepen.io/salilnaik"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={this.overLink}
                      onMouseLeave={this.notOverLink}
                    >
                      CodePen
                    </a>
                  </div>
                </div>
              </div>
              <div className="credits">
                <div className="grid-x grid-margin-x">
                  <div className="credits__item cell large-4">
                    Copyright 2020 | Salil Naik
                  </div>
                  <div className="credits__item cell large-4">
                    Site by Salil Naik
                  </div>
                  <div className="credits__item cell large-4">
                    All Rights Reserved
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
