// packages
import React, { Component } from "react";

// components
import Header from "../../components/Header.jsx";
import Rails from "./Rails.jsx";
import ContentCard from "./ContentCard.jsx";
import WorkFeature from "./WorkFeature.jsx";
import Minion from "../../components/Minion.jsx";

// css
import "../../css/pages/homepage/homepage.scss";

// images
import ScrollImg from  "../../components/images/scroll.png";

class Home extends Component {
  render() {
    return (
      <>
        <Header homepage={true}/>
        <div className="hero">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell medium-8">
                <div className="description__container">
                  <h2 className="description">
                    <span className="align-center">UI Designer</span> <span className="align-left">&amp; Front End</span> <span className="align-right">Developer</span>
                  </h2>
                  <h2 className="description">
                    A very passionate student with an aim to put{" "}
                    <span className="highlight">Goa</span> on the world's tech
                    map.
                  </h2>
                  <img src={ScrollImg} alt="scroll rounded" className="scroll-image"/>
                </div>
              </div>
              <div className="cell medium-4">
                <div className="minion-container">
                  <Minion />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rails-section">
          <Rails />
        </div>

        <div className="section">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <ContentCard />
            </div>
          </div>
        </div>

        <WorkFeature />
      </>
    );
  }
}

export default Home;
