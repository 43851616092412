import React, { Component } from "react";
// css files
import "../../css/pages/homepage/featured-work.scss";               

class WorkFeature extends Component {
  constructor(props) {
    super(props);

    window.addEventListener("scroll", this.handleScroll, true);
    this.state = {
      on_link: false,
    };
  }

  overWork = () => {
    document.getElementById("cursor").classList.add("for-work-section");
  };

  notOverWork = () => {
    document.getElementById("cursor").classList.remove("for-work-section");
  };

  handleScroll = () => {
    let dte = document.getElementsByClassName("dte")[0];
    let corona = document.getElementsByClassName("corona")[0];
    let raj = document.getElementsByClassName("raj")[0];
    let tedxgec = document.getElementsByClassName("tedxgec")[0];
    // let mtrain = document.getElementsByClassName("mtrain")[0];
    let a11y = document.getElementsByClassName("a11y")[0];
    // let landmap = document.getElementsByClassName("landmap")[0];
    let abstruct = document.getElementsByClassName("abstruct")[0];
    let workContainer = document.getElementsByClassName("featuredWork")[0];

    if (window.innerWidth > 640) {
      if (window.scrollY >= 2000 && window.scrollY < 2250) {
        dte.classList.add("active");
      } else {
        dte.classList.remove("active");
      }

      if (window.scrollY >= 2250 && window.scrollY < 2500) {
        corona.classList.add("active");
        workContainer.classList.add("yellow-two");
      } else {
        corona.classList.remove("active");
        workContainer.classList.remove("yellow-two");
      }

      if (window.scrollY >= 2500 && window.scrollY < 2750) {
        raj.classList.add("active");
        workContainer.classList.add("green-two");
      } else {
        raj.classList.remove("active");
        workContainer.classList.remove("green-two");
      }

      if (window.scrollY >= 2750 && window.scrollY < 3000) {
        tedxgec.classList.add("active");
        workContainer.classList.add("red");
      } else {
        tedxgec.classList.remove("active");
        workContainer.classList.remove("red");
      }

      // mtrain was here
      if (window.scrollY >= 3000 && window.scrollY < 3250) {
        workContainer.classList.add("purple");
        a11y.classList.add("active");
      } else {
        workContainer.classList.remove("purple");
        a11y.classList.remove("active");
      }

      // if (window.scrollY >= 3250 && window.scrollY < 3500) {
      //   workContainer.classList.add("yellow");
      //   a11y.classList.add("active");
      // } else {
      //   workContainer.classList.remove("yellow");
      //   a11y.classList.remove("active");
      // }

      // if (window.scrollY >= 3500 && window.scrollY < 3750) {
      //   workContainer.classList.add("green");
      //   landmap.classList.add("active");
      // } else {
      //   workContainer.classList.remove("green");
      //   landmap.classList.remove("active");
      // }

      if (window.scrollY >= 3250 && window.scrollY < 3500) {
        abstruct.classList.add("active");
      } else {
        abstruct.classList.remove("active");
      }
    }

    let smallScrollPosition = 1900;

    if(window.innerWidth < 641){
      if (window.scrollY >= (smallScrollPosition) && window.scrollY < (smallScrollPosition + 150)) {
        dte.classList.add("active");
      } else {
        dte.classList.remove("active");
      }

      if (window.scrollY >= (smallScrollPosition + 150) && window.scrollY < (smallScrollPosition + 300)) {
        corona.classList.add("active");
        // workContainer.classList.add("yellow-two");
      } else {
        corona.classList.remove("active");
        // workContainer.classList.remove("yellow-two");
      }

      if (window.scrollY >= (smallScrollPosition + 300) && window.scrollY < (smallScrollPosition + 450)) {
        raj.classList.add("active");
        // workContainer.classList.add("green-two");
      } else {
        raj.classList.remove("active");
        // workContainer.classList.remove("green-two");
      }

      if (window.scrollY >= (smallScrollPosition + 450) && window.scrollY < (smallScrollPosition + 600)) {
        tedxgec.classList.add("active");
        // workContainer.classList.add("red");
      } else {
        tedxgec.classList.remove("active");
        // workContainer.classList.remove("red");
      }

      // mtrain was here
      if (window.scrollY >= (smallScrollPosition + 600) && window.scrollY < (smallScrollPosition + 750)) {
        // workContainer.classList.add("purple");
        a11y.classList.add("active");
      } else {
        // workContainer.classList.remove("purple");
        a11y.classList.remove("active");
      }

      // if (window.scrollY >= 3250 && window.scrollY < 3500) {
      //   workContainer.classList.add("yellow");
      //   a11y.classList.add("active");
      // } else {
      //   workContainer.classList.remove("yellow");
      //   a11y.classList.remove("active");
      // }

      // if (window.scrollY >= 3500 && window.scrollY < 3750) {
      //   workContainer.classList.add("green");
      //   landmap.classList.add("active");
      // } else {
      //   workContainer.classList.remove("green");
      //   landmap.classList.remove("active");
      // }

      if (window.scrollY >= (smallScrollPosition + 750) && window.scrollY < (smallScrollPosition + 900)) {
        abstruct.classList.add("active");
      } else {
        abstruct.classList.remove("active");
      }
    }
  };

  render() {
    return (
      <>
        <div
          className="featuredWork"
          id="projects"
          // onMouseEnter={this.overWork}
          // onMouseLeave={this.notOverWork}
        >
          <div className="section">
            <div className="grid-container">
              <div className="grid-x grid-margin-x">
                <div className="cell large-12">
                  <h2 className="featuredWork__title">Featured Work</h2>
                  <hr className="rail__divider" />
                  <div className="featuredWork__container">
                    <a href="/dte">
                      <h3 className="featuredWork__item dte">
                        DTE - Goa Govt.
                      </h3>
                    </a>

                      <a href="http://coronatracker.in/" target="_blank" rel="noopener noreferrer">
                        <h3 className="featuredWork__item corona">
                          CoronaTracker.in
                        </h3>
                      </a>

                    <a href="https://www.rajhousinggoa.com/" target="_blank" rel="noopener noreferrer">
                      <h3 className="featuredWork__item raj">Raj Housing</h3>
                    </a>

                    <a href="https://tedxgec.com/" target="_blank" rel="noopener noreferrer">
                      <h3 className="featuredWork__item tedxgec">
                        TEDxGEC 2018
                      </h3>
                    </a>

                    {/* <a href="/" target="_blank">
                      <h3 className="featuredWork__item mtrain">m-Train</h3>
                    </a> */}

                    <a href="https://github.com/salil-naik/a11y.css" target="_blank" rel="noopener noreferrer">
                      <h3 className="featuredWork__item a11y">a11y.css</h3>
                    </a>

                    {/* <a href="" target="_blank">
                      <h3 className="featuredWork__item landmap">LandMap</h3>
                    </a> */}

                    <a href="http://abstruct.co/" target="_blank" rel="noopener noreferrer">
                      <h3 className="featuredWork__item abstruct">
                        Abstruct.co
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WorkFeature;
