import React, { Component } from "react";
import "../css/components/cursor.scss";

class Cursor extends Component {
  render() {
    return (
      <div id="cursor">
        <div id="small-cursor"></div>
      </div>
    );
  }
}

export default Cursor;
