import React from "react";
import { Link } from "react-router-dom";
import "../css/components/header.scss";

export default function Header(props) {
  let overLink = () => {
    document.getElementById("cursor").classList.add("play", "bg-transparency");
  };

  let notOverLink = () => {
    document
      .getElementById("cursor")
      .classList.remove("play", "bg-transparency");
  };

  return (
    <>
      <div className="grid-container">
        <header className="header">
          <div className="logo-area">
            <p className="logo-area__name">
              <Link
                to="/"
                onMouseEnter={overLink}
                onMouseLeave={notOverLink}
              >
                Salil Naik
              </Link>
            </p>
            <p className="logo-area__tagline">
              award-winning student developer
            </p>
          </div>
          {props.homepage ? (
            <nav className="nav hide">
              <a
                href="#projects"
                className="nav__item"
                onMouseEnter={overLink}
                onMouseLeave={notOverLink}
              >
                Projects
              </a>
              {/* <NavLink to="#" className="nav__item">Podcast</NavLink> */}
              {/* <NavLink to="#" className="nav__item">Blog</NavLink> */}
              {/* <NavLink to="#" className="nav__item">Resume</NavLink> */}
              <a
                href="#footer"
                className="nav__item"
                onMouseEnter={overLink}
                onMouseLeave={notOverLink}
              >
                Contact
              </a>
            </nav>
          ) : (
            <nav className="nav">
              <Link
                to="/"
                className="nav__item"
                onMouseEnter={overLink}
                onMouseLeave={notOverLink}
              >
                &#60; back
              </Link>
            </nav>
          )}
        </header>
      </div>
    </>
  );
}
