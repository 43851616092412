import iphone_2 from "./images/iphone-2.png";
import iphone_3 from "./images/iphone-3.png";
import iphone_4 from "./images/iphone-4.png";
import { Parallax } from "react-scroll-parallax";
import React, { useState , useEffect } from "react";

export default function Iphone() {
  const [mobile_view, setMobileView] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 641) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  },[]);
  return (
    <div className="dte-iphone-section">
      <div className="grid-container">
        <div className="grid-x grid-margin-x" style={{position:'relative'}}>
          <div className="cell medium-4">
            <Parallax y={['0px', '-100px']}>
              <img
                src={iphone_2}
                alt="mobile view of the websites"
                className="iphone"
              />
            </Parallax>
          </div>

          <div className="cell medium-4" style={{position: mobile_view? "absolute":'static'}}>
            <Parallax >
              <img
                src={iphone_3}
                alt="mobile view of the websites"
                className="iphone right"
              />
            </Parallax>
          </div>

          <div className="cell medium-4" style={{display: mobile_view?'none': 'block'}}>
            <Parallax y={['0px', '100px']}>
              <img
                src={iphone_4}
                alt="mobile view of the websites"
                className="iphone"
              />
            </Parallax>
          </div>
        </div>
      </div>
    </div>
  );
}
