import React, { Component } from "react";
import "../css/components/minion.scss";

class Minion extends Component {
  overArt = () => {
    document.getElementById("cursor").classList.add("double-tap");
  };
  notOverArt = () => {
    document.getElementById("cursor").classList.remove("double-tap");
  };
  openCodepen = () => {
    window.location.href="https://codepen.io/salilnaik/";
  }

  render() {
    return (
      <>
        <div
          onMouseEnter={this.overArt}
          onMouseLeave={this.notOverArt}
          onDoubleClick={this.openCodepen}
        >
          <div className="minion-body">
            <div className="head">
              <div className="eye">
                <div className="eyeball">
                  <div className="iris"></div>
                </div>
              </div>

              <div className="eye two">
                <div className="eyeball">
                  <div className="iris"></div>
                </div>
              </div>

              <div className="belt"></div>

              <div className="mouth"></div>
            </div>
            <div className="pant">
              <div className="pant__strap"></div>
              <div className="pant__strap two"></div>
              <div className="pant__side-pocket"></div>
            </div>
          </div>
          <div className="legs-container">
            <div className="leg"></div>
            <div className="leg two"></div>
          </div>
          <div className="hands-container">
            <div className="hands"></div>
          </div>
        </div>
      </>
    );
  }
}

export default Minion;
